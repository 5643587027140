import { StrictMode } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { rootReducer, rootSaga } from "src/redux";
import MainRoutes from "./MainRoutes.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH_CONFIG, REDIRECT } from "./common/Auth/auth0-variables.js";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import ReactGA from "react-ga4";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createCompassThemeObject } from "ctm-compass-react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { setupPendo } from "./services/pendo";
import {
  CHAKRA_COLOR_MODE,
  LIGHT_MODE_PREF,
  NO_COLOR_PREFERENCE,
} from "./common/constants.js";

const components = {
  Popover: {
    variants: {},
    baseStyle: {
      body: {
        bg: "white",
      },
    },
    defaultProps: {},
  },
};
let colorPref = localStorage.getItem(CHAKRA_COLOR_MODE);

if (!colorPref && localStorage.getItem(LIGHT_MODE_PREF)) colorPref = "light";

if (!colorPref) sessionStorage.setItem(NO_COLOR_PREFERENCE, "true");

const config = {
  initialColorMode: colorPref || "dark",
  useSystemColorMode: false,
};

const themeObject: Record<string, any> = createCompassThemeObject({
  colors: { primary: "var(--primaryColor)" },
});

themeObject.components = { ...themeObject.components, ...components };
themeObject.config = config;

const theme = extendTheme(themeObject);

const history = createBrowserHistory();
const sagaMiddleWare = createSagaMiddleware();

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);

const auth0Domain = AUTH_CONFIG.domain;
const auth0ClientId = AUTH_CONFIG.clientId;
const auth0CallbackUrl = AUTH_CONFIG.callbackUrl;
const auth0Audience = AUTH_CONFIG.audience;

function configureStore(preloadedState?: unknown) {
  const store = createStore(
    rootReducer(), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        sagaMiddleWare
        // ... other middlewares ...
      )
    )
  );
  return store;
}

export const store = configureStore();

const queryClient = new QueryClient();

export type AppDispatch = typeof store.dispatch;

sagaMiddleWare.run(rootSaga);

const onRedirectCallback = (appState) => {
  if (appState?.returnTo) localStorage.setItem(REDIRECT, appState.returnTo);
};

export const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
    autoTrackPageVisitTime: true,
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();

setupPendo();

const App = () => (
  <StrictMode>
    <ChakraProvider theme={theme} resetCSS={false} disableGlobalStyle={true}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Auth0Provider
              domain={auth0Domain}
              clientId={auth0ClientId}
              cacheLocation="localstorage"
              onRedirectCallback={onRedirectCallback}
              authorizationParams={{
                redirect_uri: auth0CallbackUrl,
                audience: auth0Audience,
              }}
            >
              <MainRoutes history={history} />
            </Auth0Provider>
          </Provider>
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </ChakraProvider>
  </StrictMode>
);

export default App;
