import { useState, useRef } from "react";
import { connect } from "react-redux";
import { Image, Icon } from "semantic-ui-react";
import { setGoogleAnalyticsEvent } from "src/common/helpers";
import { redirectToLogout } from "src/redux/actions/application";
import { useHistory } from "react-router";
import ImpersonationForm from "./ImpersonationForm";
import { handleSSORedirect } from "src/services/ssoRedirect";
import { UserI } from "src/types/User";
import { SSOLinkI } from "src/types/SSOLink";
import {
  Flex,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  UnorderedList,
  useColorMode,
} from "@chakra-ui/react";
import LightDarkIcon from "src/icons/LightDarkIcon";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  IndividualIcon,
  TickIcon,
  TripReturnIcon,
} from "ctm-compass-react";
import { setColorPreferences } from "src/services/application";

/** User Profile for header with dropdown menu */

const renderAvatar = (user: UserI) => {
  if (
    user.givenName &&
    user.givenName.length &&
    user.familyName &&
    user.familyName.length
  ) {
    return (
      <span className="avatar">
        <span>{`${user.givenName[0]}${user.familyName[0]}`}</span>
      </span>
    );
  }

  if (user.picture) {
    return (
      <Image
        src={user.picture}
        avatar
        className="avatar"
        style={{ height: "40px", width: "40px" }}
      />
    );
  }
};

interface ProfileI {
  colors: any;
  user: UserI;
  globalSsoLinks?: SSOLinkI[];
  initializeImpersonation: (params: {
    email: string;
    id: string;
    token: string;
  }) => void;
  clearImpersonation: () => void;
  isImpersonating: boolean;
}

const Profile = ({
  colors,
  user,
  globalSsoLinks,
  initializeImpersonation,
  clearImpersonation,
  isImpersonating,
}: ProfileI) => {
  const [isEditingLightMode, setEditingLightMode] = useState(false);
  const [isGlobalSSOMenu, setInGlobalSSOMenu] = useState(false);
  const [isInImpersonationMenu, setInImpersonationMenu] = useState(false);

  const history = useHistory();
  const triggerRef = useRef(null);
  const { colorMode, toggleColorMode } = useColorMode();

  const handleToggle = (val) => {
    setGoogleAnalyticsEvent({
      action: "portal_dashboard_theme_switch",
      category: val ? "breezy" : "galaxy",
    });
    setColorPreferences(colorMode === "dark" ? colors.light : colors.dark);
    toggleColorMode();
  };

  const renderTrigger = () => {
    const currUser = (user || {}) as UserI;
    if (!currUser.givenName) {
      return (
        <button
          className="unstyled-button"
          data-aut="UserProfile"
          ref={triggerRef}
        >
          <Icon name="caret down" />
        </button>
      );
    }
    const name = currUser.familyName
      ? `${currUser.givenName} ${currUser.familyName}`
      : currUser.givenName;

    return (
      <button
        className="unstyled-button"
        id="user-profile"
        data-aut="UserProfile"
        ref={triggerRef}
      >
        {renderAvatar(currUser)}
        <span className="greeting">{name}</span>
      </button>
    );
  };

  function renderDefaultMenu() {
    return (
      <UnorderedList p={0} m={0} mr={0} className="profile-menu-list">
        <ListItem onClick={() => setEditingLightMode(true)}>
          <button className="unstyled-button profile-menu-button">
            <Flex justify={"space-between"} alignItems={"center"}>
              <Flex>
                <LightDarkIcon
                  fill="#ABB1BB"
                  className="profile-menu-left-icon"
                />{" "}
                Color Theme
              </Flex>
              <ChevronRightIcon color="var(--subtleGray)" />
            </Flex>
          </button>
        </ListItem>
        {globalSsoLinks?.length ? (
          <ListItem>
            <button
              className="unstyled-button profile-menu-button"
              onClick={() => setInGlobalSSOMenu(true)}
            >
              <Flex justify={"space-between"} alignItems={"center"}>
                <Flex alignItems={"center"}>
                  <TripReturnIcon
                    color="#ABB1BB"
                    className="profile-menu-left-icon"
                  />
                  Switch Accounts
                </Flex>
                <ChevronRightIcon color="var(--subtleGray)" />
              </Flex>
            </button>
          </ListItem>
        ) : null}
        {Boolean(user.canImpersonate || isImpersonating) && (
          <ListItem>
            <button
              className="unstyled-button profile-menu-button"
              onClick={() => setInImpersonationMenu(true)}
            >
              <Flex justify={"space-between"} alignItems={"center"}>
                <Flex alignItems={"center"}>
                  <IndividualIcon
                    color="#ABB1BB"
                    className="profile-menu-left-icon"
                  />{" "}
                  Impersonate User
                </Flex>
                <ChevronRightIcon color="var(--subtleGray)" />
              </Flex>
            </button>
          </ListItem>
        )}
        <ListItem>
          <button
            className="unstyled-button profile-menu-button"
            onClick={() => history.push("/logout")}
          >
            <Flex alignItems={"center"}>Logout</Flex>
          </button>
        </ListItem>
      </UnorderedList>
    );
  }

  function renderLightModeMenu() {
    return (
      <UnorderedList p={0} m={0} mr={0} className="profile-menu-list">
        <ListItem color="var(--defaultGray)">
          <button
            className="unstyled-button profile-menu-button"
            onClick={() => setEditingLightMode(false)}
          >
            <Flex justify={"space-between"} alignItems={"center"}>
              <ChevronLeftIcon
                className="profile-menu-left-icon"
                color="var(--subtleGray)"
              />
              <span>Switch Themes</span>
              <span />
            </Flex>
          </button>
        </ListItem>
        <ListItem color="var(--defaultGray)">
          <button
            className={
              colorMode === "light"
                ? "unstyled-button profile-menu-button checked"
                : "unstyled-button profile-menu-button"
            }
            onClick={() => handleToggle(true)}
          >
            <Flex justify={"space-between"} alignItems={"center"}>
              <span>Breezy (light)</span>
              {colorMode === "light" ? <TickIcon /> : <span />}
            </Flex>
          </button>
        </ListItem>
        <ListItem color="var(--defaultGray)">
          <button
            className={
              colorMode === "light"
                ? "unstyled-button profile-menu-button"
                : "unstyled-button profile-menu-button checked"
            }
            onClick={() => handleToggle(false)}
          >
            <Flex justify={"space-between"} alignItems={"center"}>
              <span>Galaxy (dark)</span>
              {colorMode === "light" ? <span /> : <TickIcon />}
            </Flex>
          </button>
        </ListItem>
      </UnorderedList>
    );
  }

  function renderImpersonationMenu() {
    return (
      <UnorderedList p={0} m={0} mr={0} className="profile-menu-list">
        <ListItem color="var(--defaultGray)">
          <button
            className="unstyled-button profile-menu-button"
            onClick={() => setInImpersonationMenu(false)}
          >
            <Flex justify={"space-between"} alignItems={"center"}>
              <ChevronLeftIcon
                className="profile-menu-left-icon"
                color="var(--subtleGray)"
              />
              <span>Impersonate User</span>
              <span />
            </Flex>
          </button>
        </ListItem>
        <ListItem color="var(--defaultGray)" padding="10px 16px">
          <ImpersonationForm
            clearImpersonation={clearImpersonation}
            isImpersonating={isImpersonating}
            initializeImpersonation={initializeImpersonation}
          />
        </ListItem>
      </UnorderedList>
    );
  }

  function renderSSOLinkMenu() {
    return (
      <UnorderedList p={0} m={0} mr={0} className="profile-menu-list">
        <ListItem color="var(--defaultGray)">
          <button
            className="unstyled-button profile-menu-button"
            onClick={() => setInGlobalSSOMenu(false)}
          >
            <Flex justify={"space-between"} alignItems={"center"}>
              <ChevronLeftIcon
                className="profile-menu-left-icon"
                color="var(--subtleGray)"
              />
              <span>Switch Accounts</span>
              <span />
            </Flex>
          </button>
        </ListItem>
        {globalSsoLinks.map((link) => {
          const url = link.isDirectLink ? link.href : undefined;
          return (
            <ListItem key={link.id}>
              <a
                className="profile-menu-button"
                href={url}
                target={link.openInNewWindow ? "_blank" : undefined}
                rel="noreferrer"
                data-aut={`Profile|SSOLink|${link.label}`}
                tabIndex={0}
                onClick={() => {
                  if (!link.isDirectLink)
                    handleSSORedirect(link, history, isImpersonating);
                  setGoogleAnalyticsEvent({
                    action: "portal_dashboard_global_sso",
                    category: link.identifier,
                  });
                }}
              >
                {link.label}
              </a>
            </ListItem>
          );
        })}
      </UnorderedList>
    );
  }

  function renderMenuContent() {
    if (isEditingLightMode) return renderLightModeMenu();
    if (isGlobalSSOMenu) return renderSSOLinkMenu();
    if (isInImpersonationMenu) return renderImpersonationMenu();
    return renderDefaultMenu();
  }

  function resetPopoverState() {
    // If we reset popover state right on the onClose event, you can see the UI change
    // Delay it a bit to avoid the weird flicker
    setTimeout(() => {
      setEditingLightMode(false);
      setInGlobalSSOMenu(false);
      setInImpersonationMenu(false);
    }, 100);
  }

  return (
    <Popover onClose={resetPopoverState} isLazy>
      <PopoverTrigger>{renderTrigger()}</PopoverTrigger>
      <PopoverContent>
        <PopoverBody p={0} color="var(--defaultGray)">
          {renderMenuContent()}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    loading: state.user.loading,
    globalSsoLinks: state.globalSsoLinks,
    isImpersonating: state.impersonation.isImpersonating,
    colors: state.application.colors,
  };
};

const mapDispatchToProp = (dispatch) => ({
  redirectToLogout: () => dispatch(redirectToLogout()),
});

export default connect(mapStateToProps, mapDispatchToProp)(Profile);
