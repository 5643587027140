import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutUser } from "src/services/logout";
import { setGoogleAnalyticsEvent } from "src/common";
import { useLightMode } from "src/common/hooks/lightMode";

function Logout({ user }) {
  const { logout, user: authUser } = useAuth0();
  const { lightMode } = useLightMode();
  useEffect(() => {
    if (authUser) {
      setGoogleAnalyticsEvent({
        action: "portal_dashboard_logout",
      });
      logoutUser({
        user,
        authUser,
        logout,
      });
    }
  }, [logout, authUser, user]);

  return (
    <div
      className={`simple-message-container${lightMode ? " light-mode" : ""}`}
    >
      <div className="message-container">
        <Image
          src="/images/ctm-logo-landscape.png"
          title="Corporate Travel Management"
          alt="CTM Logo"
        />
        <p>You have been successfully logged out.</p>
        <p>
          <NavLink to="/login">Click Here</NavLink> to log back in.
        </p>
      </div>
    </div>
  );
}

export default Logout;
