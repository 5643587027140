import { COLOR_HEADERFOOTER, COLOR_PRIMARY } from "src/common/constants";

export const setColorPreferences = ({ primary, headerFooter }) => {
  const root = document.documentElement;
  if (primary) {
    root.style.setProperty("--primaryColor", primary);
    root.style.setProperty("--primaryCompassColor", primary);
    localStorage.setItem(COLOR_PRIMARY, primary);
  }
  if (headerFooter) {
    root.style.setProperty("--headerFooterColor", headerFooter);
    localStorage.setItem(COLOR_HEADERFOOTER, headerFooter);
  }
};

/**
 * When a route changes, we use this method to update application state such as
 * header, aside menu and other misc things in the future
 */

const isMobile = document.body.clientWidth <= 1200;
export const onRouteChange = (route) => {
  let asideCondensed = isMobile,
    headerCondensed = false,
    headerSticky = false,
    isWidgetVisible = true,
    pageClass = "";

  route = route || "";

  if (route) {
    pageClass = `page ${route}`;
    asideCondensed = true;
    headerCondensed = true;
    headerSticky = true;
    isWidgetVisible = false;

    return {
      portal: { pageClassName: pageClass, isWidgetVisible: isWidgetVisible },
      asideMenuCondensed: asideCondensed, // also condense menu and header for all pages except dashboard
      header: { isCondensed: headerCondensed, isSticky: headerSticky },
      pageError: false,
    };
  }

  // If there is no route, we are navigating to the dashboard so we want to set our asidemenu condensed preference back to true
  return {
    portal: { pageClassName: pageClass, isWidgetVisible: isWidgetVisible },
    asideMenuCondensed: asideCondensed, // also condense menu and header for all pages except dashboard
    header: { isCondensed: headerCondensed, isSticky: headerSticky },
    asideMenuCondensedPref: true,
    pageError: false,
  };
};

export const getFlightStatus = (statusId, statusEnum, returnObj = false) => {
  if (returnObj) return statusEnum[statusId];
  else return statusEnum[statusId].label;
};
