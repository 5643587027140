import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { useLightMode } from "src/common/hooks/lightMode";
import { Loading } from "src/components";

const FareForecaster = React.lazy(() => import("./FareForecaster"));

function FareForecasterPage(props) {
  usePageInit("Smart Portal - Fare Forecaster");
  const { lightMode } = useLightMode();
  return (
    <Suspense
      fallback={
        <Loading addContainer active loadingText="Loading Fare Forecaster" />
      }
    >
      <FareForecaster {...props} lightMode={lightMode} />
    </Suspense>
  );
}

export default FareForecasterPage;
