import {
  REDIRECT_TO_LOGOUT,
  SET_COLORS,
  SET_CAROUSELS,
  SET_HEADER_STATE,
  SET_ASIDE_MENU,
  SET_ASIDE_MENU_PREF,
  SET_IS_GLOBAL_ERROR_MAP,
  SET_PAGE_ERROR,
  SET_SMART_PORTAL_STATE,
  TOGGLE_WIDGET_EDIT,
} from "src/redux/actions/application";
import {
  COLOR_HEADERFOOTER,
  COLOR_PRIMARY,
  CHAKRA_COLOR_MODE,
  LIGHT_MODE_PREF,
} from "src/common/constants";
import { supportsLocalStorage } from "src/common/helpers";
import { onRouteChange, setColorPreferences } from "src/services/application";
import commonColors from "src/common/colors";
import { CarouselDataI } from "src/components/Carousel";

const flightStatusEnum = {
  1: {
    label: "On Time",
    isVisible: true,
  },
  2: {
    label: "Canceled",
    isVisible: false,
  },
  3: {
    label: "Diverted",
    isVisible: false,
  },
  4: {
    label: "Data Source Needed",
    isVisible: false,
  },
  5: {
    label: "Landed",
    isVisible: true,
  },
  6: {
    label: "Not Operational",
    isVisible: false,
  },
  7: {
    label: "Redirected",
    isVisible: false,
  },
  8: {
    label: "Not Departed",
    isVisible: true,
  },
  9: {
    label: "Unknown",
    isVisible: false,
  },
  10: {
    label: "Delayed",
    isVisible: true,
  },
  11: {
    label: "En-Route",
    isVisible: true,
  },
};

export type FlightStatusEnumI = typeof flightStatusEnum;

function getLocalStorageItem(constant: string, parseJSON = false) {
  if (supportsLocalStorage()) {
    const val = localStorage.getItem(constant);
    return parseJSON ? JSON.parse(val) : val;
  } else {
    return false;
  }
}

export const defaultColors = {
  dark: {
    primary: commonColors.lightBlueColor,
    headerFooter: commonColors.darkHeaderColor,
  },
  light: {
    primary: commonColors.blueColor,
    headerFooter: commonColors.lightHeaderColor,
  },
};

export interface ApplicationStateI {
  header: {
    isCondensed: boolean;
    isSticky: boolean;
  };
  portal: {
    isWidgetVisible: boolean;
    pageClassName: string;
  };
  widgetEditMode: boolean;
  asideMenu?: boolean;
  asideMenuCondensed: boolean;
  asideMenuCondensedPref: boolean;
  isGlobalRiskMap: boolean;
  pageError: boolean;
  colors: typeof defaultColors;
  flightStatusEnum: FlightStatusEnumI;
  carousels: CarouselDataI[];
  redirectToLogout?: boolean;
}

export const getInitialState = () => {
  let initialState: ApplicationStateI = {
    header: { isCondensed: false, isSticky: false },
    portal: { isWidgetVisible: true, pageClassName: "" },
    widgetEditMode: false,
    asideMenuCondensed: document.body.clientWidth <= 1200,
    asideMenuCondensedPref: true,
    isGlobalRiskMap: false,
    pageError: false,
    colors: { ...defaultColors },
    flightStatusEnum,
    carousels: [],
  };

  // set page class name to route pathname
  const loc = window ? window.location : null;
  if (loc) {
    const route = (loc.pathname || "").substring(1);
    const appState = onRouteChange(route);
    initialState = {
      ...initialState,
      header: {
        ...initialState.header,
        ...appState.header,
      },
      asideMenu: appState.asideMenuCondensed,
      portal: {
        ...initialState.portal,
        ...appState.portal,
      },
    };
  }
  const curPrimary = getLocalStorageItem(COLOR_PRIMARY);
  const curHeaderFooter = getLocalStorageItem(COLOR_HEADERFOOTER);
  const colors =
    localStorage.getItem(CHAKRA_COLOR_MODE) === "light" ||
    localStorage.getItem(LIGHT_MODE_PREF)
      ? { ...defaultColors.light }
      : { ...defaultColors.dark };
  if (curPrimary) colors.primary = curPrimary;
  if (curHeaderFooter) colors.headerFooter = curHeaderFooter;
  setColorPreferences(colors);
  return initialState;
};

const initialState = getInitialState();

const application = (state = initialState, action: any): ApplicationStateI => {
  switch (action.type) {
    case SET_HEADER_STATE:
      return {
        ...state,
        header: {
          ...state.header,
          ...action.state,
        },
      };
    case SET_ASIDE_MENU:
      return {
        ...state,
        asideMenuCondensed: action.payload,
      };
    case SET_ASIDE_MENU_PREF:
      return {
        ...state,
        asideMenuCondensedPref: action.payload,
      };
    case SET_IS_GLOBAL_ERROR_MAP:
      return {
        ...state,
        isGlobalRiskMap: true,
      };
    case SET_PAGE_ERROR:
      return {
        ...state,
        pageError: true,
      };
    case SET_SMART_PORTAL_STATE:
      return {
        ...state,
        ...action.state,
      };
    case SET_COLORS: {
      if (action.payload.lightMode) {
        setColorPreferences(
          action.payload.lightMode
            ? action.payload.colors.light
            : action.payload.colors.dark
        );
      } else {
        const mode = localStorage.getItem(CHAKRA_COLOR_MODE);

        setColorPreferences(
          mode === "light" ||
            (!localStorage.getItem(CHAKRA_COLOR_MODE) &&
              localStorage.getItem(LIGHT_MODE_PREF))
            ? action.payload.colors.light
            : action.payload.colors.dark
        );
      }

      const result = {
        ...state,
        colors: action.payload.colors,
      };
      return result;
    }
    case SET_CAROUSELS:
      return {
        ...state,
        carousels: action.payload,
      };
    case TOGGLE_WIDGET_EDIT:
      return {
        ...state,
        widgetEditMode: !state.widgetEditMode,
      };
    case REDIRECT_TO_LOGOUT:
      return {
        ...state,
        redirectToLogout: true,
      };
    default:
      return state;
  }
};

export default application;
