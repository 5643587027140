import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import SideSubMenu from "./SideSubMenu";
import { Icon, Image, Popup } from "semantic-ui-react";
import colors from "src/common/colors";
import { useSafeSetState } from "src/common/hooks";
import { History } from "history";
import { SideMenuItemI } from "src/types/SideMenuItem";
import { SSOLinkI } from "src/types/SSOLink";
import { useLightMode } from "src/common/hooks/lightMode";

/**
 * Dynamically generated menu containing My Apps, Tools and Widgets along with edit option for each category
 */

const initialState = {
  renderBrandLogo: true,
};

interface AsideMenuI {
  history: History;
  isCondensed: boolean;
  path: string;
  links: SideMenuItemI[];
  loading: boolean;
  obts: SSOLinkI[];
  search: string;
  ssoLinks: SSOLinkI[];
  tools: SideMenuItemI[];
  userLogo: string;
  widgets: SideMenuItemI[];
}

const AsideMenu = ({
  history,
  isCondensed,
  path,
  links,
  loading,
  obts,
  search,
  ssoLinks,
  tools,
  userLogo,
  widgets,
}: AsideMenuI) => {
  const [state, safeSetState] = useSafeSetState(initialState);
  const { lightMode } = useLightMode();

  const handleImageError = () => {
    safeSetState({ renderBrandLogo: false });
  };

  const renderHomeIcon = path !== "/";
  const params = new URLSearchParams(search);
  const paramId = params.get("id");
  return (
    <aside>
      {renderHomeIcon && (
        <div className="ui borderless vertical aside-menu menu">
          <div className="item">
            <NavLink
              to="/"
              activeStyle={
                lightMode
                  ? { color: colors.lightModeTextColor }
                  : { color: "white" }
              }
              className="dashboard-link"
              data-aut="Aside|Home"
            >
              {isCondensed ? (
                <Popup
                  trigger={<Icon name="home" size="large" />}
                  content={"Dashboard"}
                  on="hover"
                  position="right center"
                  basic
                  style={{ zIndex: 99999, fontSize: "12px" }}
                />
              ) : (
                <Icon name="home" size="large" />
              )}
              <span>Dashboard</span>
            </NavLink>
          </div>
        </div>
      )}
      <div className="brand-logo">
        {state.renderBrandLogo && userLogo && (
          <Image src={userLogo} onError={handleImageError} alt="Brand Logo" />
        )}
      </div>
      {!loading && (ssoLinks.length || obts.length) ? (
        <SideSubMenu
          history={history}
          label="My Apps"
          isCondensed={isCondensed}
          isSSO={true}
          items={[...obts, ...ssoLinks]}
        />
      ) : null}
      {tools && tools.length ? (
        <SideSubMenu
          label="Tools"
          isCondensed={isCondensed}
          items={tools}
          path={path}
          paramId={paramId}
        />
      ) : null}
      {links && links.length ? (
        <SideSubMenu label="Links" isCondensed={isCondensed} items={links} />
      ) : null}
      {widgets && widgets.length ? (
        <SideSubMenu
          label="Widgets"
          isCondensed={isCondensed}
          items={widgets}
          isWidget={true}
        />
      ) : null}
    </aside>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  links: state.links,
  obts: state.obts,
  ssoLinks: state.ssoLinks,
  tools: state.tools,
  widgets: state.widgets,
});

export default connect(mapStateToProps)(AsideMenu);
