import { useEffect } from "react";
import { Dimmer, Container, Loader } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { CHAKRA_COLOR_MODE } from "src/common";

const Login = ({ location }) => {
  const { loginWithRedirect } = useAuth0();
  const locationObj = location?.state?.from;
  let redirectUrl = null;
  if (locationObj) {
    const { pathname, search } = locationObj;
    redirectUrl = `${pathname}${search}`;
  }
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const connection = params.get("connection");
    const lightModePref = localStorage.getItem(CHAKRA_COLOR_MODE);
    const device = lightModePref === "light" ? "portal-light" : "portal-dark";
    loginWithRedirect({
      appState: { returnTo: redirectUrl ? redirectUrl : "/" },
      authorizationParams: {
        device,
        ...(Boolean(connection) && { connection }),
      },
    });
  }, []);

  return (
    <Container fluid style={{ height: "100%" }}>
      <Dimmer active>
        <Loader>Please Wait</Loader>
      </Dimmer>
    </Container>
  );
};

export default Login;
