import { redirectToLogout } from "src/redux/actions/application";
import { store } from "src/App";
import { getToken } from "..";
import jwt_decode from "jwt-decode";

/**
 * Wrapper for handling fetch errors since fetch won't handle it natively
 */
export const handleErrors = (response) => {
  // If the status is a 401, our token in no longer valid and the user needs to log back in.
  if (response.status === 401 && store) {
    store.dispatch(redirectToLogout());
  }
  if (!response.ok || response.status > 206) {
    if (response?.json) {
      return response.json().then((res) => {
        const error = new Error(res.message);
        error.code = response.status;
        error.message = res.message;
        throw error;
      });
    }
    throw new Error();
  }
  return response;
};

// Code used to fake a click to download csv
export function saveFileFromBlobData(data, fileName) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.setAttribute("style", "display: none;");
  const blob = new Blob([data], { type: "octet/stream" }),
    url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

const dataToCsvURI = (data) =>
  encodeURI(
    `data:text/csv;charset=utf-8,${data.map((row) => row.join(",")).join(`\n`)}`
  );

export const convertDataToCSVDownload = (
  rows = [],
  filename = "example.csv"
) => {
  const csvEl = document.createElement("a");
  const url = dataToCsvURI(rows);
  csvEl.href = url;
  csvEl.setAttribute("download", filename);
  csvEl.click();
  csvEl.remove();
};

const mockDebtors = {
  "0000000TES": true,
  "00CSMEDEMO": true,
  SBT000DEMO: true,
  USA1234567: true,
  USLIGHTZZZ: true,
  PLAYWRIGHT: true,
  DELOITDEMO: true,
  ZILLOWDEMO: true,
};

export const shouldMockData = () => {
  const user = jwt_decode(getToken().token);
  const ID = user["https://www.ctmsmart.com/debtor"];
  return mockDebtors[ID];
};
