import { Fragment } from "react";
import moment, { Moment } from "moment";
import { select } from "d3-selection";
import { scaleLinear } from "d3-scale";
import { axisLeft, axisBottom } from "d3-axis";
import { range } from "d3-array";
import {} from "d3"; // Do not remove this empty import from d3, it fixes a crash if no other code is using d3
import { handleErrors } from "src/common/shared";
import { get } from "src/common/apiClient";
import { DATE_FORMAT, TIME_FORMAT } from "src/common/constants";
import { ApprovalItinerary } from "src/types/Approve";
import { store } from "src/App";
import ReactGA from "react-ga4";

// Chooses a random entry in a given array (mainly used for test coverage)
export function chooseRandom(ar) {
  const index = Math.floor(Math.random() * ar.length);
  return ar[index];
}

// Creates a clone of an object/array and also clones any objects/arrays that may be nested inside of it
export function cloneDeep(value) {
  if (!(value instanceof Object)) return value;
  const result = new value.constructor();
  if (value.constructor === Array) {
    value.forEach((item) => result.push(cloneDeep(item)));
  } else if (typeof value === "function") {
    return "function";
  } else {
    for (const key in value) {
      result[key] = cloneDeep(value[key]);
    }
  }
  return result;
}

export type DebouncedFunction = (
  val1?: string,
  val2?: (val: string) => void
) => void;

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function formatDate(date) {
  const momentDate = moment.isMoment(date) ? date : moment(date);
  if (momentDate.isValid()) {
    const dateFormat = store.getState()?.user?.data?.dateFormat || DATE_FORMAT;
    return momentDate.format(dateFormat);
  } else {
    return "N/A";
  }
}

export function formatDateTime(date) {
  const momentDate = moment.isMoment(date) ? date : moment(date);
  if (momentDate.isValid()) {
    const dateTimeFormat =
      store.getState()?.user?.data?.dateTimeFormat ||
      `${DATE_FORMAT} ${TIME_FORMAT}`;
    return momentDate.format(dateTimeFormat);
  } else {
    return "N/A";
  }
}

export function formatTime(dateTime) {
  const momentDate = moment.isMoment(dateTime) ? dateTime : moment(dateTime);
  if (momentDate.isValid()) {
    return momentDate.format(TIME_FORMAT);
  } else {
    return "N/A";
  }
}

export const mergeRefs = (...refs) => {
  return (node) => {
    for (const ref of refs) {
      ref.current = node;
    }
  };
};

export function minutesToMilliseconds(minutes = 1) {
  return minutes * 60 * 1000;
}

export function isObject(val) {
  return typeof val === "object" && val !== null;
}

export function toQueryString(data) {
  function parseDataType(data) {
    if (moment.isMoment(data)) return data;
    if (typeof data === "object" && data !== null) {
      return JSON.stringify(data);
    }
    return data;
  }
  return Object.keys(data)
    .map((k) => {
      return (
        encodeURIComponent(k) + "=" + encodeURIComponent(parseDataType(data[k]))
      );
    })
    .join("&");
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - (min + 1)) + min);
}

export function getRandomNumber(min, max) {
  return (
    Math.floor(Math.random() * (max * 100 - (min + 1) * 100) + min * 100) / 100
  );
}

export function titleCase(str) {
  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
}

export function titleCaseMultiple(str) {
  // returns all words in string separated by spaces to title case
  return str
    .split(" ")
    .map((s) => titleCase(s))
    .join(" ");
}

export function renderRoundedNumber(num, symbol = "", dec = 2) {
  let prefix = "";
  if (num < 0) {
    prefix += "-";
    num *= -1;
  }
  prefix += symbol;
  let suffix = "";
  const mult = Math.pow(10, dec);
  if (num >= 1000000) {
    num = num / 1000000;
    suffix = "M";
  } else if (num >= 1000) {
    num = num / 1000;
    suffix = "K";
  }
  if (mult) {
    num = Math.floor(num * mult) / mult;
  }
  return `${prefix}${num}${suffix}`;
}

function getTimeDiffRounded(start, end) {
  // Make sure we "round up" days for car/hotel durations. So, if you have something like a 40 hour car rental, it shows as 2 days.
  return (
    moment(end)
      .set("hour", 12)
      .set("minute", 0)
      .diff(moment(start).set("hour", 12).set("minute", 0), "days") || 1
  );
}

function getFlightFlattedItin(flight) {
  const { carrierFullName, locations, cabinClass, operatingFlightNumber } =
    flight;
  const { origin, destination } = locations;
  const departureCode = origin.airportCode ? `(${origin.airportCode})` : "";
  const departureCity = origin.cityName ? origin.cityName : "";
  const departureTime = origin.localTime;
  const arrivalCode = destination.airportCode
    ? `(${destination.airportCode})`
    : "";
  const arrivalCity = destination.cityName ? destination.cityName : "";
  const arrivalTime = destination.localTime;
  const reservationType = cabinClass
    ? ` Class: ${titleCaseMultiple(cabinClass)}`
    : ``;
  return {
    ...flight,
    displayText: (
      <p>
        {carrierFullName} {operatingFlightNumber}
      </p>
    ),
    startDisplay: "Departure:",
    startLocation: `${departureCity} ${departureCode}`,
    startTime: departureTime,
    endDisplay: "Arrival:",
    endLocation: `${arrivalCity} ${arrivalCode}`,
    endTime: arrivalTime,
    reservationType: reservationType,
    time: moment(departureTime),
    type: "flight",
  };
}

export function sortItinerariesByTime(itin: ApprovalItinerary) {
  const flattedItins = [];
  if (!itin) return flattedItins;

  if (itin.flights && itin.flights.outbound && itin.flights.outbound.length) {
    itin.flights.outbound.forEach((fl) => {
      const flattenedItin = getFlightFlattedItin(fl);
      flattedItins.push(flattenedItin);
    });
  }

  // if (itin.flights && itin.flights.return && itin.flights.return.length) {
  //   itin.flights.return.forEach((fl) => {
  //     const flattenedItin = getFlightFlattedItin(fl);
  //     flattedItins.push(flattenedItin);
  //   });
  // }

  if (itin.carRentals && itin.carRentals.length) {
    itin.carRentals.forEach((cr) => {
      const duration = getTimeDiffRounded(cr.dateStart, cr.dateEnd);
      const durationText = `Days: ${duration}`;
      const pickupLocation = cr.pickUp ? ` ${cr.pickUp}` : ``;
      const dropoffLocation = cr.dropOff ? ` ${cr.dropOff}` : ``;
      const type = cr.carType && cr.carType.trim();
      const reservationType = type && type.length ? `Type: ${type}` : ``;
      const vendorName =
        cr.location && cr.location.pickUp && cr.location.pickUp.title
          ? cr.location.pickUp.title
          : "N/A";
      const confirmationNumber = cr.referenceNumber;

      flattedItins.push({
        ...cr,
        displayText: (
          <p>
            {vendorName} #{confirmationNumber}
          </p>
        ),
        startDisplay: "Pick Up:",
        startLocation: pickupLocation,
        startTime: cr.dateStartLocal,
        endDisplay: "Drop Off:",
        endLocation: dropoffLocation,
        endTime: cr.dateEndLocal,
        reservationType: reservationType,
        time: moment(cr.dateStart),
        type: "carRental",
        duration: durationText,
      });
    });
  }

  if (itin.hotels && itin.hotels.length) {
    itin.hotels.forEach((h) => {
      // Hotels have a checkout earlier than a checkin, but we want to not have that remove a night from the duration text
      const duration = getTimeDiffRounded(
        h.statuses.stay.dateStart,
        h.statuses.stay.dateEnd
      );
      const durationText = `Nights: ${duration}`;
      const reservationType = h.roomClass ? `Room: ${h.roomClass}` : ``;
      const displayText = (
        <Fragment>
          <p>{h.company.title}</p>
          <p>{h.company.address}</p>
        </Fragment>
      );

      flattedItins.push({
        ...h,
        displayText: displayText,
        startDisplay: "Check In:",
        startTime: formatDate(h.statuses.stay.dateStartLocal),
        endDisplay: "Check Out:",
        endTime: formatDate(h.statuses.stay.dateEndLocal),
        reservationType: reservationType,
        time: moment(h.statuses.stay.dateStart),
        type: "hotel",
        duration: durationText,
      });
    });
  }

  if (itin?.rails?.length) {
    itin.rails.forEach((rail) => {
      const startMoment = moment(rail.locations.origin.utcTime);
      const endMoment = moment(rail.locations.destination.utcTime);
      const duration = moment.duration(startMoment.diff(endMoment));
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes()) % 60;
      const hoursText = hours > 0 ? `${hours} hrs ` : "";
      const minutesText = minutes > 0 ? `${minutes} mins` : "";
      flattedItins.push({
        ...rail,
        displayText: (
          <p>
            {rail.marketingVendor || "N/A"} #{rail.trainNumber}
          </p>
        ),
        startDisplay: "Origin:",
        startLocation: rail.locations.origin.cityName,
        startTime: formatDateTime(rail.locations.origin.localTime),
        endDisplay: "Destination:",
        endLocation: rail.locations.destination.cityName,
        endTime: formatDateTime(rail.locations.destination.localTime),
        time: startMoment,
        type: "rail",
        duration: `${hoursText}${minutesText}`,
      });
    });
  }

  if (itin?.limos?.length) {
    itin.limos.forEach((limo) => {
      const startMoment = moment(limo.dateStart);
      const endMoment = moment(limo.dateEnd);
      const duration = moment.duration(startMoment.diff(endMoment));
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes()) % 60;
      const hoursText = hours > 0 ? `${hours} hrs ` : "";
      const minutesText = minutes > 0 ? `${minutes} mins` : "";
      const displayText = limo.telephone;
      const reservationType = `Limo Reference #${limo.referenceNumber}`;
      flattedItins.push({
        ...limo,
        displayText,
        startDisplay: "Pickup: ",
        startLocation: limo.pickUp,
        endDisplay: "Dropoff: ",
        endLocation: limo.dropOff,
        endTime: limo.dateEndLocal,
        startTime: limo.dateStartLocal,
        reservationType,
        type: "limo",
        duration: `${hoursText}${minutesText}`,
      });
    });
  }

  return flattedItins.sort((a, b) => {
    if (b.time.isBefore(a.time)) return 1;
    else return -1;
  });
}

export function supportsLocalStorage() {
  try {
    return "localStorage" in window && window["localStorage"] !== null;
  } catch (e) {
    return false;
  }
}

// Takes a user's selection
export function mapTimeFrames(timeFrames: {
  previous: number | null;
  upcoming: number | null;
}) {
  let startDate, endDate;
  switch (timeFrames.previous) {
    case 0:
      startDate = moment().startOf("day");
      break;
    case 1:
      startDate = moment().startOf("day").subtract(1, "days");
      break;
    case 2:
      startDate = moment().startOf("day").subtract(2, "days");
      break;
    case 3:
      startDate = moment().startOf("day").subtract(7, "days");
      break;
    case 4:
      startDate = moment().startOf("day").subtract(14, "days");
      break;
    case 5:
      startDate = moment().startOf("day").subtract(30, "days");
      break;
    case 6:
      startDate = moment().startOf("month");
      break;
    default:
      startDate = moment().startOf("day").subtract(1, "days");
  }

  switch (timeFrames.upcoming) {
    case 1:
      endDate = moment().endOf("day").add(1, "days");
      break;
    case 2:
      endDate = moment().endOf("day").add(2, "days");
      break;
    case 3:
      endDate = moment().endOf("day").add(7, "days");
      break;
    case 4:
      endDate = moment().endOf("day").add(14, "days");
      break;
    case 5:
      endDate = moment().endOf("day").add(30, "days");
      break;
    case 6:
      endDate = moment().endOf("month");
      break;
    default:
      endDate = moment().endOf("day").add(7, "days");
  }
  return { startDate, endDate } as { startDate: Moment; endDate: Moment };
}

// Function that memoizes GET requests
export function memoFetch() {
  // Create our caches to store our values in a closure
  // We have one cache for urls with no params, and one for those with params for simplicity
  const cache = {};
  const cacheWithParams = {};
  // Return a memoized function that uses the closure cache to remember previous calls
  return (url, params) => {
    // Stringify params so that if we get an array/object we do a simple flat check
    // This won't work if the order of the array is changed but the values are the same, but it works well enough for our purposes here
    // since our code generally pushes out params in the same order.
    const stringifiedParams = JSON.stringify(params);
    // Check to see if we've called this url already
    if (cache[url] || cacheWithParams[url]) {
      // Check to see if we have params, if not, we can just return the previous successful result of the call to the url
      if (!params) return new Promise((resolve) => resolve(cache[url]));
      else {
        // If we have a params, check to see if we've cached the result under the key of the stringified params
        if (cacheWithParams[url][stringifiedParams]) {
          return new Promise((resolve) =>
            resolve(cache[url][stringifiedParams])
          );
        }
      }
    } else {
      // We have no cached value to now it's time to fetch
      if (params) {
        get({ url, params })
          .then(handleErrors)
          .then((res) => res.json())
          .then((res) => {
            // Check to see if we've cached a response on this url with different params
            if (cacheWithParams[url]) {
              // Store our result on a new key of the stringified params
              cacheWithParams[url][stringifiedParams] = res;
              return new Promise((resolve) => resolve(res));
            } else {
              // Otherwise we need to create an object for the url
              cacheWithParams[url] = {};
              // and then create a key with the stringified params and cache the result on it prior to returning it as a promise
              cacheWithParams[url][stringifiedParams] = res;
              return new Promise((resolve) => resolve(res));
            }
          });
      } else
        return get({ url })
          .then(handleErrors)
          .then((res) => res.json())
          .then((res) => {
            // Cache the result and return it as a promise
            cache[url] = res;
            return new Promise((resolve) => resolve(res));
          });
    }
  };
}

// Takes an integer and returns in a string format like 2h 32m
export function numberToHoursMins(num) {
  if (typeof num !== "number" || num <= 0) return "";
  let hours = 0;
  while (num >= 60) {
    hours++;
    num -= 60;
  }
  return `${hours}h ${num}m`;
}

// Converts a risk rating to its corresponding css class
export const riskNumberToClass = {
  1: "lowest-risk",
  2: "low-risk",
  3: "medium-risk",
  4: "high-risk",
  5: "severe-risk",
};

export const riskValueToText = {
  1: "Trivial",
  2: "Low",
  3: "Moderate",
  4: "High",
  5: "Extreme",
};

export function setPageTitle(title: string) {
  document.title = title;
}

/**
 * Sorts an array of flights based on moment utc time
 */
export function sortFlights(flight1, flight2) {
  if (
    moment(flight1.locations.origin.utcTime).isSameOrBefore(
      flight2.locations.origin.utcTime
    )
  )
    return -1;
  else return 1;
}

/**
 * Sorts an array of segments based on moment utc time
 */
export function sortSegments(segment1, segment2) {
  if (moment(segment1.dateStart).isSameOrBefore(segment2.dateStart)) return -1;
  else return 1;
}

function sortByLocation(locationOne, locationTwo, airportCodes) {
  // If we are missing either location, then we will prioritize the itinerary that has a location
  if (!locationTwo && locationOne) return -1;
  else if (!locationOne && locationTwo) return 1;
  if (locationOne && locationTwo) {
    // If both locations are present, we consult the airportCodes object which stores the amount
    // of itineraries with risk at each location. The location with more itineraries gets priority.
    if (airportCodes[locationOne] > airportCodes[locationTwo]) return -1;
    else if (airportCodes[locationTwo] > airportCodes[locationOne]) return 1;
    // If both locations have the same amount of itineraries, we sort alphabetically
    return locationOne < locationTwo ? -1 : 1;
  }
}

/**
 * Sorts an array of segments based on custom business logic for risk itineraries
 * Sort by severity of impact - risk number, then # of pax affected, then alphabetically
 */
export function sortRiskSegments(segment1, segment2, airportCodes) {
  // We first check to see if we have riskLevels available and they are not the same
  if (segment1.riskLevel !== segment2.riskLevel) {
    // Prioritize the segment with the higher riskLevel
    if (!segment2.riskLevel || segment1.riskLevel > segment2.riskLevel)
      return -1;
    else return 1;
  }

  // If we can't sort based on riskLevel, we base it on the location of the risk
  const locationOne =
    segment1.locations.destination &&
    segment1.locations.destination.airportCode;
  const locationTwo =
    segment2.locations.destination &&
    segment2.locations.destination.airportCode;

  if (locationOne !== locationTwo && airportCodes) {
    return sortByLocation(locationOne, locationTwo, airportCodes);
  }
  // If we've made it this far, we have two segments with the same location and risk so we will sort them normally (chronologically)
  return sortSegments(segment1, segment2);
}

export function sortRiskSummaries(
  segment1,
  segment2,
  sortByNumPassengers?: boolean
) {
  // We first check to see if we have riskLevels available and they are not the same
  if (segment1.riskRatingID !== segment2.riskRatingID) {
    if (!segment2.riskRatingID || segment1.riskRatingID > segment2.riskRatingID)
      return -1;
    else return 1;
  }

  if (segment1.airportCode !== segment2.airportCode && sortByNumPassengers) {
    if (segment1.passengers.length !== segment2.passengers.length) {
      return segment1.passengers.length > segment2.passengers.length ? -1 : 1;
    }
  }

  return segment1.airportCode > segment2.airportCode ? 1 : -1;
}

/**
 * Converts to spinal-case, all lowercase separated by hyphens
 */
export function toSpinalCase(value) {
  if (typeof value !== "string") return value;
  return value
    .replace(/'/g, "")
    .replace(/[^a-z0-9-]/gi, "-")
    .replace(/-{2,}/g, "-")
    .toLowerCase();
}

const onlyMobile = { minWidth: 320, maxWidth: 767 };
const onlyTablet = { minWidth: 768, maxWidth: 991 };
const onlyComputer = { minWidth: 992 };
const onlyLargeScreen = { minWidth: 1200, maxWidth: 1919 };
const onlyWidescreen = { minWidth: 1920 };
export const media = {
  isMobile: () => {
    return window.innerWidth < onlyMobile.minWidth;
  },
  isTablet: () => {
    return window.innerWidth >= onlyTablet.minWidth;
  },
  isTabletDown: () => {
    return window.innerWidth < onlyTablet.minWidth;
  },
  isComputer: () => {
    return window.innerWidth >= onlyComputer.minWidth;
  },
  isComputerDown: () => {
    return window.innerWidth < onlyLargeScreen.minWidth;
  },
  isLargeScreen: () => {
    return window.innerWidth >= onlyLargeScreen.minWidth;
  },
  isLargeScreenDown: () => {
    return window.innerWidth < onlyWidescreen.minWidth;
  },
  isWidescreen: () => {
    return window.innerWidth >= onlyWidescreen.minWidth;
  },
};

export function sendEmail(e, email) {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  if (email) window.location.href = `mailto:${email}`;
}

export class SvgGrid {
  options;
  /** Grid width minus margins */
  get width() {
    return (
      this.options.width - this.options.margin.left - this.options.margin.right
    );
  }
  /** Grid height minus margins */
  get height() {
    return (
      this.options.height - this.options.margin.top - this.options.margin.bottom
    );
  }
  scale;
  axes;
  container;
  bottomZeroStartingPoint;

  constructor(options) {
    this.options = {
      axes: {
        bottom: {
          domain: null,
          tickCount: null,
          linePositionFn: null,
        },
        left: {
          domain: null,
          tickCount: null,
          linePositionFn: null,
        },
      },
      margin: { top: 20, right: 20, bottom: 20, left: 80 },
      width: 700,
      height: 450,
      useDiffZeroOrigin: false,
      ...options,
    };

    this.scale = {
      left: scaleLinear().range([this.height, 0]),
      bottom: scaleLinear().range([0, this.width]),
    };

    if (this.options.axes.left.domain) {
      this.scale.left.domain(this.options.axes.left.domain);
    }
    if (this.options.axes.bottom.domain) {
      this.scale.bottom.domain(this.options.axes.bottom.domain);
    }

    this.axes = {
      bottom: axisBottom(this.scale.bottom),
      left: axisLeft(this.scale.left),
    };
  }

  draw(svgContainer) {
    this.container = svgContainer;
    const x0Scale = this.scale.bottom;
    const y0Scale = this.scale.left;
    const x0Axis = this.axes.bottom;
    const y0Axis = this.axes.left;

    if (this.options.useDiffZeroOrigin) {
      const zeroIndex = x0Scale.ticks().indexOf(0);
      this.bottomZeroStartingPoint = x0Scale(x0Scale.ticks()[zeroIndex]);
    } else {
      this.bottomZeroStartingPoint = 0;
    }

    const chartSvg = svgContainer
      .append("g")
      .attr("class", "canvas")
      .attr(
        "transform",
        `translate(${this.options.margin.left},${this.options.margin.top})`
      );

    // ********** Create grid border and background color
    const gridSvg = chartSvg.append("g").attr("class", "grid");
    const gridBox = gridSvg.append("g").attr("class", "box");
    gridBox
      .append("rect")
      .attr("class", "bg")
      .attr("x", this.bottomZeroStartingPoint)
      .attr("y", 0)
      .attr("width", this.width - this.bottomZeroStartingPoint)
      .attr("height", this.height);
    gridBox
      .append("rect")
      .attr("class", "bg2")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.bottomZeroStartingPoint)
      .attr("height", this.height);

    // *********** create grid lines
    const xTickCount =
      this.options.axes.bottom.tickCount || x0Scale.ticks().length;
    const yTickCount =
      this.options.axes.left.tickCount || y0Scale.ticks().length;
    const linesSvg = gridSvg.append("g").attr("class", "lines");
    // draw x lines
    const xLinePos =
      this.options.axes.bottom.linePositionFn ||
      ((index) => x0Scale.ticks()[index]);
    const lineHeight = this.height;
    linesSvg
      .append("g")
      .attr("class", "vertical")
      .selectAll("line")
      .data(range(xTickCount))
      .enter()
      .append("line")
      .each(function (range) {
        // use function instead of arrow function to access the correct "this"
        const lineSvg = select(this);
        const tick = xLinePos(range);
        const xVal = x0Scale(tick);
        lineSvg
          .attr("x1", xVal)
          .attr("x2", xVal)
          .attr("y1", 0)
          .attr("y2", lineHeight);
        if (tick === 0) lineSvg.attr("class", "dashed");
      });
    // draw y lines
    const yLinePos =
      this.options.axes.left.linePositionFn ||
      ((index) => y0Scale(y0Scale.ticks()[index]));
    linesSvg
      .append("g")
      .attr("class", "horizontal")
      .selectAll("line")
      .data(range(yTickCount))
      .enter()
      .append("line")
      .attr("x1", 0)
      .attr("x2", this.width)
      .attr("y1", yLinePos)
      .attr("y2", yLinePos);

    // *********** Create axes
    const axesSvg = svgContainer.append("g").attr("class", "axes");
    axesSvg
      .append("g")
      .attr("class", "left")
      .attr(
        "transform",
        `translate(${this.options.margin.left},${this.options.margin.top})`
      )
      .call(y0Axis);
    axesSvg
      .append("g")
      .attr("class", "bottom")
      .attr(
        "transform",
        `translate(${this.options.margin.left},${
          this.options.height - this.options.margin.bottom
        })`
      )
      .call(x0Axis);
  }
}

// Detects whether or not a browser is IE (which we don't support)
export function getIEVersion() {
  const sAgent = window.navigator.userAgent;
  const Idx = sAgent.indexOf("MSIE");

  // If IE, return version number.
  if (Idx > 0)
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)), 10);
  // If IE 11 then look for Updated user agent string.
  else if (navigator.userAgent.match(/Trident\/7\./)) return 11;
  else return 0; //It is not IE
}

export function formatPrice(price, digits = 0) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return formatter.format(price);
}

export function validateURL(url) {
  // eslint-disable-next-line
  var regex = new RegExp(
    "^" +
      // protocol identifier (optional)
      // short syntax // still required
      "(?:(?:(?:https?|ftp):)?\\/\\/)" +
      // user:pass BasicAuth (optional)
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
      // IP address exclusion
      // private & local networks
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      "(?:" +
      "(?:" +
      "[a-z0-9\\u00a1-\\uffff]" +
      "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
      ")?" +
      "[a-z0-9\\u00a1-\\uffff]\\." +
      ")+" +
      // TLD identifier name, may end with dot
      "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
      ")" +
      // port number (optional)
      "(?::\\d{2,5})?" +
      // resource path (optional)
      "(?:[/?#]\\S*)?" +
      "$",
    "i"
  );
  return regex.test(url);
}

export function validateEmail(email) {
  // eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

export function scrollToTop() {
  document
    .querySelector("body")
    .scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

export function setGoogleAnalyticsEvent({
  action,
  category = null,
  label = null,
}: {
  action: string;
  category?: string;
  label?: string;
}) {
  const eventParams = {
    action,
    ...{ category: category || " " },
    ...(Boolean(label) && { label }),
  };
  ReactGA.event(eventParams);
}
