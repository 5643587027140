import React, { Suspense } from "react";
import { usePageInit } from "src/common/hooks";
import { useLightMode } from "src/common/hooks/lightMode";
import { Loading } from "src/components";

const TravelerTracker = React.lazy(() => import("./TravelerTracker"));

function TravelerTrackerPage(props) {
  usePageInit("Smart Portal - Traveler Tracker");
  const { lightMode } = useLightMode();
  return (
    <Suspense
      fallback={
        <Loading
          addContainer
          active
          loadingText={
            props.isGlobalRiskMap
              ? "Loading Risk Map"
              : "Loading Traveler Tracker"
          }
        />
      }
    >
      <TravelerTracker
        {...props}
        // Use a key to force the map to remount when changing lightmode
        key={lightMode}
        lightMode={lightMode}
      />
    </Suspense>
  );
}

export default TravelerTrackerPage;
