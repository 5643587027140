import { Button, Input, LightMode } from "@chakra-ui/react";
import { validateEmail } from "src/common";
import { useSafeSetState } from "src/common/hooks";
import { impersonateUser } from "src/services/impersonation";

const initialState = {
  email: "",
  loading: false,
  error: false,
};

const ImpersonationForm = ({
  isImpersonating,
  initializeImpersonation,
  clearImpersonation,
}) => {
  const [{ email, error, loading }, safeSetState] =
    useSafeSetState(initialState);

  const handleLogin = () => {
    safeSetState({ loading: true, error: false });
    impersonateUser(email)
      .then((r: any) => {
        safeSetState({
          loading: false,
          email,
        });
        initializeImpersonation({ id: r.id, token: r.token, email });
      })
      .catch(() => safeSetState({ loading: false, error: true }));
  };

  const handleLogout = () => {
    clearImpersonation();
  };

  if (isImpersonating) {
    return (
      <>
        <p>Impersonating User: {isImpersonating}</p>
        <LightMode>
          <Button
            onClick={handleLogout}
            colorScheme="green"
            variant="solid"
            size="sm"
          >
            Exit
          </Button>
        </LightMode>
      </>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleLogin();
      }}
    >
      <Input
        placeholder="Impersonate User"
        value={email}
        onChange={(e) => safeSetState({ email: e.target.value })}
        disabled={loading}
        size={"sm"}
        color="black"
      />
      {error && (
        <p className="impersonate-error">
          Impersonate User is disabled for this account.
        </p>
      )}
      <LightMode>
        <Button
          className="impersonate-button"
          isLoading={loading}
          disabled={!validateEmail(email) || loading}
          type="submit"
          size="sm"
          mt={2}
          colorScheme="green"
          variant="solid"
        >
          Log In
        </Button>
      </LightMode>
    </form>
  );
};

export default ImpersonationForm;
